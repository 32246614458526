import React, { useEffect } from "react"
import { useHubspotForm } from "@aaronhayes/react-use-hubspot-form"
import { isBrowser } from "@util/helper"

interface Props {
  hubspotFormID: string
}

const HubpostForm = (props: Props) => {
  const { hubspotFormID } = props

  const { loaded, error, formCreated } = useHubspotForm({
    portalId: "4096483",
    formId: hubspotFormID,
    target: "#cta-my-hubspot-form",
    region: "na1",
    width: "100%",
  })

  useEffect(() => {
    if (isBrowser()) {
      window.addEventListener("message", event => {
        if (
          event.data.type === "hsFormCallback" &&
          event.data.eventName === "onFormSubmitted"
        ) {
          // sending form fill event
          if (window.dataLayer) {
            window.dataLayer.push({
              event: "hubspot_form_submission",
              formId: hubspotFormID,
            })
          }
        }
      })
    }

    return () => {
      window.removeEventListener("message", event => {})
    }
  }, [])

  return <div id="cta-my-hubspot-form"></div>
}

export default HubpostForm
