import React, { useState, useEffect } from "react"
import LayOut from "../components/layout"
import BlocksContent from "../components/blocksContent"
import SEO from "../components/seo"
import { navigate } from "gatsby"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"
import * as styles from "../components/partners/premiumPartners/style.module.scss"
import HubspotForm from "../components/shared/hubspotForm"

const xl = 1200
const l = 992
const md = 768
const sm = 576

const Wrapper = styled.div`
  padding: 150px 0 150px 0;
  display: flex;
  width: 70%;
  margin: auto;
  flex-direction: column;
  position: relative;
  @media only screen and (max-width: ${xl}px) {
    padding-top: 50px;
    width: 80%;
  }
  @media only screen and (max-width: ${l}px) {
    width: 90%;
  }
`

const TopContainer = styled.div`
  display: flex;
  width: 80%;
  margin: 50px auto;
  justify-content: space-between;

  @media only screen and (max-width: ${sm}px) {
    flex-direction: column;
    margin: 20px auto;
  }
`

const Container = styled.div`
  position: relative;
  display: flex;
  border-radius: 14px;
  background-color: white;
  padding: 50px 30px;
  overflow: hidden;
  padding-bottom: 60px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

  @media only screen and (max-width: ${md}px) {
    flex-direction: column;
  }
`

const Column = styled.div`
  display: flex;
  margin: 30px 0;
  justify-content: space-between;
  grid-gap: 20px;
  @media only screen and (max-width: ${sm}px) {
    flex-direction: column;
  }
`

const HalfWidthContainer = styled.div`
  width: 50%;

  @media only screen and (max-width: ${md}px) {
    width: 100%;
  }
`

const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  // margin-right: 150px;
  padding-right: 30px;
  flex: 3;
  @media only screen and (max-width: ${l}px) {
    margin-right: 50px;
  }
`

const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  flex: 1;
  align-items: center;

  @media only screen and (max-width: ${md}px) {
    margin-bottom: 20px;
  }
`

const ColorLine = styled.span`
  position: absolute;
  display: block;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 8px;
  background-color: ${props =>
    props.backgroundColor ? props.backgroundColor : "transparent"};
`

const StyledContainer = styled(Container)`
  @media only screen and (max-width: ${md}px) {
    flex-direction: column-reverse;
  }
`

const PartnersTemplate = ({ pageContext }) => {
  const {
    partnerName,
    logo,
    descriptionPop,
    colorlist,
    description,
    leftColumn,
    rightColumn,
    badge,
    featuredImage,
    script,
    hubspotFormID,
  } = pageContext

  const onClick = e => {
    e.preventDefault()
    navigate(-1)
  }

  return (
    <LayOut>
      <SEO
        title={partnerName || ""}
        description={description || ""}
        image={logo.asset.gatsbyImageData || null}
        url={`/partners/${partnerName
          .toLowerCase()
          .replace(/\s+/g, "-")
          .slice(0, 200)}`}
      />
      <Wrapper>
        <TopContainer>
          <div>
            <h2>Partners</h2>
            <a onClick={onClick}>&lt; Back</a>
          </div>

          {logo && (
            <GatsbyImage
              image={logo.asset.gatsbyImageData}
              alt={logo.asset.originalFilename}
              className={styles.image}
              imgStyle={{ objectFit: "contain" }}
            />
          )}
        </TopContainer>
        <StyledContainer>
          <LeftContainer>
            <h3>{partnerName}</h3>
            <p>{description}</p>

            <Column>
              {leftColumn && (
                <HalfWidthContainer>
                  <BlocksContent blocks={leftColumn._rawColumnContent} />
                </HalfWidthContainer>
              )}

              {rightColumn && (
                <HalfWidthContainer>
                  <BlocksContent blocks={rightColumn._rawColumnContent} />
                </HalfWidthContainer>
              )}
            </Column>

            <h4>About Us</h4>
            {descriptionPop && (
              <BlocksContent blocks={descriptionPop._rawColumnContent} />
            )}
          </LeftContainer>
          <RightContainer>
            {featuredImage && (
              <GatsbyImage
                image={featuredImage.asset.gatsbyImageData}
                alt={featuredImage.asset.originalFilename}
                className={styles.image}
                imgStyle={{ objectFit: "contain" }}
                style={{ width: "250px", height: "250px" }}
              />
            )}
            {badge && (
              <GatsbyImage
                image={badge.asset.gatsbyImageData}
                alt={badge.asset.originalFilename}
                className={styles.image}
                imgStyle={{ objectFit: "contain" }}
                style={{ width: "200px", height: "100px" }}
              />
            )}
            {/* <div id="my-hubspot-form"></div> */}
            {hubspotFormID && <HubspotForm hubspotFormID={hubspotFormID} />}
          </RightContainer>
          <ColorLine backgroundColor={colorlist} />
        </StyledContainer>
      </Wrapper>
    </LayOut>
  )
}

export default PartnersTemplate
