// extracted by mini-css-extract-plugin
export var cardButton = "style-module--cardButton--jZX9y";
export var cardDescription = "style-module--cardDescription--0RgrP";
export var cardTitle = "style-module--cardTitle--zFWfx";
export var colorLine = "style-module--colorLine--VCi3V";
export var container = "style-module--container--irAB8";
export var image = "style-module--image--ZbYQz";
export var partner = "style-module--partner--USuyE";
export var partners = "style-module--partners--SxCTq";
export var section = "style-module--section--QJoVl";
export var title = "style-module--title--PPbZ3";